import React from "react"
import { Link } from "gatsby"
import styled from 'styled-components'

import Layout from "../components/layout"

const Container = styled.div`
  width: 70%;
`

const Heading = styled.h1`
  margin-top: 12px;
  font-size: 32px;
  line-height: 1.5;
  font-weight: 400;
`

const SubHeading = styled.h2`
  font-size: 20px;
  line-height: 34px;
  font-weight: 400;
`

const Divider = styled.hr`
  width: 60%;
  background-color: #FFFFFF;
  margin: 12px 0px;
`
const IndexPage = () => (
    <Layout>
      <Container>
        <Heading>
          Judy Lee is currently a UX Designer at Zuum Transportation
        </Heading>

        <Divider />

        <SubHeading>
          Previously was working as a UI/UX Designer at FreshWorks Studio in Victoria, BC, Canada
        </SubHeading>
      </Container>
    </ Layout>
)

export default IndexPage
